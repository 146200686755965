import {
    Box,
    Card as ChakraCard,
    CardBody as ChakraCardBody,
    CardBodyProps as ChakraCardBodyProps,
    CardFooter as ChakraCardFooter,
    CardFooterProps as ChakraCardFooterProps,
    CardProps as ChakraCardProps,
    Checkbox as ChakraCheckbox,
    CheckboxGroup as ChakraCheckboxGroup,
    CheckboxGroupProps as ChakraCheckboxGroupProps,
    CheckboxProps as ChakraCheckboxProps,
    Heading as ChakraHeading,
    Divider,
    Flex,
    FormControl,
    FormControlProps,
    FormLabel,
    Icon,
    IconButton,
    Input,
    InputGroup,
    InputProps,
    InputRightElement,
    PlacementWithLogical,
    Text,
    Tooltip,
} from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@common/icons';
import { cx } from '@common/utils';
import * as React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

interface RootProps {
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
    shouldReset?: boolean;
    setShouldReset?: (value: boolean) => void;
}
function Root({ children, onSubmit, shouldReset, setShouldReset }: React.PropsWithChildren<RootProps>) {
    const [resetCounter, setResetCounter] = React.useState(0);

    // resets entire form via react's key diff check
    // we don't have to worry about timing of children resetting etc.
    React.useEffect(() => {
        if (!shouldReset || !setShouldReset) {
            return;
        }

        setShouldReset(false);
        setResetCounter((resetCounter) => {
            return resetCounter + 1;
        });
    }, [shouldReset, setShouldReset]);

    return (
        <Box as="form" key={resetCounter} onSubmit={onSubmit}>
            {children}
        </Box>
    );
}

function Card({ children, ...props }: React.PropsWithChildren<ChakraCardProps>) {
    return (
        <ChakraCard display="flex" direction="column" width="full" {...props}>
            {children}
        </ChakraCard>
    );
}

function CardBody({ children, ...props }: React.PropsWithChildren<ChakraCardBodyProps>) {
    return (
        <ChakraCardBody pb="0" {...props}>
            <Flex direction="column" gap="1rem">
                {children}
            </Flex>
        </ChakraCardBody>
    );
}

function CardFooter({ children, ...props }: React.PropsWithChildren<ChakraCardFooterProps>) {
    return (
        <ChakraCardFooter display="flex" justifyContent="flex-end" {...props}>
            {children}
        </ChakraCardFooter>
    );
}

function Heading({ children }: React.PropsWithChildren) {
    return (
        <ChakraHeading
            as="h2"
            lineHeight="1.56"
            fontWeight="600"
            fontSize="1.125rem"
            color="gray.900"
            letterSpacing="normal"
            width="full"
        >
            {children}
        </ChakraHeading>
    );
}
interface ItemProps {
    id: string;
    label?: string;
    labelFontSize?: string;
    shouldShowDivider?: boolean;
    help?: string;
}
function HItem({
    id,
    label,
    children,
    labelFontSize,
    shouldShowDivider = false,
    ...props
}: React.PropsWithChildren<ItemProps & FormControlProps>) {
    return (
        <>
            <FormControl
                id={id}
                display="flex"
                justifyContent="space-between"
                alignItems="start"
                minHeight="40px"
                gap="32px"
                {...props}
            >
                {label ? (
                    <FormLabel
                        lineHeight="20px"
                        fontWeight="400"
                        fontSize={cx(labelFontSize || '0.875rem')}
                        color="gray.900"
                        margin="0"
                        minW="256px"
                        whiteSpace="nowrap"
                    >
                        {label}
                    </FormLabel>
                ) : null}
                <Flex display="flex" direction="column" gap="0.25rem" w="full" alignSelf="center">
                    {children}
                </Flex>
            </FormControl>
            {shouldShowDivider ? <Divider orientation="horizontal" /> : null}
        </>
    );
}

function VItem({
    id,
    label,
    children,
    labelFontSize,
    shouldShowDivider = false,
    help,
    ...props
}: React.PropsWithChildren<ItemProps & FormControlProps>) {
    return (
        <>
            <FormControl id={id} display="flex" flexDirection="column" gap="0.25rem" width="full" {...props}>
                {label ? (
                    <Flex mx={0.5} w="full" justifyContent="space-between" alignContent="center">
                        <FormLabel
                            lineHeight="20px"
                            fontWeight="400"
                            fontSize={cx(labelFontSize || '0.875rem')}
                            color="gray.900"
                            margin="0"
                            whiteSpace="nowrap"
                        >
                            {label}
                        </FormLabel>
                        {help ? (
                            <Tooltip hasArrow label={help} placement="top">
                                <Text fontSize="13px" p={0} mr={1.5} color="gray.400" as="span">
                                    <Icon as={QuestionOutlineIcon} />
                                </Text>
                            </Tooltip>
                        ) : null}
                    </Flex>
                ) : null}
                {children}
            </FormControl>
            {shouldShowDivider ? <Divider orientation="horizontal" /> : null}
        </>
    );
}

function CtInput({ value: initialValue, onChange, ...props }: InputProps) {
    const [value, setValue] = React.useState(() => initialValue || '');

    return (
        <Input
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange?.(e);
            }}
            {...props}
        />
    );
}

// customized input handles read only styles, ellipses and adds a tooltip
interface CtInputCustomProps extends InputProps {
    showEllipses?: boolean;
    tooltipPlacement?: PlacementWithLogical;
}
function CtInputCustom({
    value,
    onChange,
    isReadOnly,
    showEllipses = true,
    tooltipPlacement = 'bottom-start',
    ...props
}: CtInputCustomProps) {
    return (
        <Tooltip hasArrow placement={tooltipPlacement} label={value}>
            <Input
                value={value}
                onChange={onChange}
                isReadOnly={isReadOnly || !onChange || false}
                {...(isReadOnly
                    ? {
                          color: 'gray.400',
                          borderColor: 'gray.200',
                          cursor: 'not-allowed',
                          _hover: { borderColor: 'gray.200' },
                          _focus: {
                              borderColor: 'gray.200',
                              boxShadow: 'none',
                          },
                      }
                    : {})}
                {...(showEllipses
                    ? {
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                      }
                    : {})}
                {...props}
            />
        </Tooltip>
    );
}

function InputPassword({ placeholder, ...props }: InputProps) {
    const [shouldShow, setShouldShow] = React.useState(() => false);

    const hidePasswordIconStyle = `
        input::-ms-reveal, 
        input::-ms-clear {
            display: none;
        }
    `;

    return (
        <InputGroup display="flex" flexDirection="column">
            <style>{hidePasswordIconStyle}</style>
            <CtInput type={shouldShow ? 'text' : 'password'} placeholder={placeholder ?? 'Enter password'} {...props} />
            <InputRightElement>
                <IconButton
                    onClick={() => {
                        setShouldShow((show) => !show);
                    }}
                    aria-label="show password"
                    icon={shouldShow ? <FiEye /> : <FiEyeOff />}
                    color="gray.500"
                    background="transparent"
                />
            </InputRightElement>
        </InputGroup>
    );
}

function InputError({ children }: React.PropsWithChildren) {
    return children ? (
        <Text color="red.600" fontSize="small">
            {children}
        </Text>
    ) : null;
}

function CheckboxGroup({ children, ...props }: ChakraCheckboxGroupProps) {
    return (
        <ChakraCheckboxGroup {...props}>
            <Flex direction="column" gap="1rem">
                {children}
            </Flex>
        </ChakraCheckboxGroup>
    );
}

function Checkbox({ children, ...props }: ChakraCheckboxProps) {
    return (
        <ChakraCheckbox fontWeight="400" size="lg" spacing="8px" {...props}>
            <Text fontWeight="400">{children}</Text>
        </ChakraCheckbox>
    );
}

export const CtForm = Object.assign(Root, {
    Card,
    CardBody,
    CardFooter,
    Heading,
    HItem,
    VItem,
    Input: CtInput,
    InputCustom: CtInputCustom,
    InputPassword,
    InputError,
    CheckboxGroup,
    Checkbox,
});
