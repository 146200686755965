import { Icon, ToastPosition, useToast } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { FiAlertOctagon } from 'react-icons/fi';
import { MdInfo, MdInfoOutline, MdOutlineWarningAmber } from 'react-icons/md';

export default function useCustomToast() {
    const toast = useToast();

    const showCustomToast = ({
        title,
        description,
        status = 'info',
        duration = 5000,
        isClosable = true,
        position = 'top-right',
        variant = 'subtle',
        icon,
        ...props
    }: {
        title: string;
        description?: string;
        status?: 'info' | 'success' | 'error' | 'warning';
        duration?: number;
        isClosable?: boolean;
        position?: ToastPosition;
        variant?: string;
        icon?: IconType;
        [key: string]: any;
    }) => {
        let customStyles = {};
        let defaultIcon: IconType = MdInfo;
        let iconColor = '';
        let iconSize = '20px';

        // Common styles
        const commonStyles = {
            flexDirection: 'row',
            alignItems: 'start',
            justifyContent: 'start',
            gap: '0.5rem',
            borderRadius: '4px',
        };

        // Specific styles and icon settings based on status
        switch (status) {
            case 'success':
                defaultIcon = FaRegCircleCheck;
                iconColor = 'green.500';
                iconSize = '20px';
                customStyles = {
                    ...commonStyles,
                    backgroundColor: 'green.100',
                    color: 'green.700',
                };
                break;
            case 'error':
                defaultIcon = FiAlertOctagon;
                iconColor = 'red.500';
                iconSize = '20px';
                customStyles = {
                    ...commonStyles,
                    backgroundColor: 'red.100',
                    color: 'orange.700',
                };
                break;
            case 'warning':
                defaultIcon = MdOutlineWarningAmber;
                iconColor = 'yellow.500';
                iconSize = '20px';
                customStyles = {
                    ...commonStyles,
                    backgroundColor: 'orange.100',
                    color: 'orange.700',
                };
                break;
            case 'info':
            default:
                defaultIcon = MdInfoOutline;
                iconColor = 'brand.500';
                iconSize = '20px';
                customStyles = {
                    ...commonStyles,
                    backgroundColor: 'brand.100',
                    color: 'brand.700',
                };
                break;
        }

        toast({
            title,
            description,
            status,
            duration,
            isClosable,
            position,
            variant,
            icon: icon ? (
                <Icon as={icon} color={iconColor} boxSize={iconSize} />
            ) : (
                <Icon as={defaultIcon} color={iconColor} boxSize={iconSize} />
            ),
            containerStyle: customStyles,
            ...props,
        });
    };

    return showCustomToast;
}
