import { Box, BoxProps, Icon, useMultiStyleConfig } from '@chakra-ui/react';

export type IconAvatarProps = {
    /**
     * The size of the avatar
     */
    size?: 'xs' | 'sm' | 'md' | 'lg';
    /**
     * The icon to show in the avatar
     */
    icon: React.ElementType;
    /**
     * The color of the avatar. Should be a chakra icon with levels of 100, 200, 300, 400, 500, 600, 700, 800, 900
     */
    color?: string;
} & BoxProps;

export function IconAvatar({ size = 'md', icon, color = 'gray', ...rest }: IconAvatarProps) {
    const chakraStyles = useMultiStyleConfig('IconAvatar', { size });

    return (
        <Box __css={chakraStyles.circle} bg={`${color}.100`} {...rest}>
            <Icon __css={chakraStyles.icon} as={icon} color={`${color}.500`} />
        </Box>
    );
}
